namespace $.$$ {

	$mol_style_define( $.$hyoo_intern_cabinet_norole, {

		Info: $hyoo_intern_cabinet_page_style,
		Resume_page: $hyoo_intern_cabinet_page_style,
		Sign: $hyoo_intern_cabinet_page_style,

		Resume_link: {
			width: '20rem',
			flex: {
				grow: 0,
			},
			alignSelf: 'center',
			justifyContent: 'center',
		},

		Send_resume: {
			width: '20rem',
			flex: {
				grow: 0,
			},
			alignSelf: 'center',
			justifyContent: 'center',
			margin: {
				bottom: $mol_gap.block,
			},
		},

	} )

}
