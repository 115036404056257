namespace $ {
	export class $hyoo_intern_catalog_vacancies extends $hyoo_intern_catalog_entities {
		
		/**
		 * ```tree
		 * menu_title \Вакансии (заявки на стажеров)
		 * ```
		 */
		menu_title() {
			return "Вакансии (заявки на стажеров)"
		}
		
		/**
		 * ```tree
		 * Card_view* $hyoo_intern_vacancy_card vacancy <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_view(id: any) {
			const obj = new this.$.$hyoo_intern_vacancy_card()
			
			obj.vacancy = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card_edit* $hyoo_intern_vacancy_card vacancy <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_edit(id: any) {
			const obj = new this.$.$hyoo_intern_vacancy_card()
			
			obj.vacancy = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * item* $hyoo_intern_vacancy
		 * ```
		 */
		@ $mol_mem_key
		item(id: any) {
			const obj = new this.$.$hyoo_intern_vacancy()
			
			return obj
		}
	}
	
}

