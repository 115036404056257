namespace $.$$ {

	$mol_style_define( $hyoo_intern_cabinet_intern, {

		Profile: $hyoo_intern_cabinet_page_style,
		Resume: $hyoo_intern_cabinet_page_style,
		Career_school: $hyoo_intern_cabinet_page_style,
		Tests: $hyoo_intern_cabinet_page_style,
		Championship: $hyoo_intern_cabinet_page_style,

	 } )

}
