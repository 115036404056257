namespace $ {
	export class $hyoo_intern_cabinet extends $mol_book2_catalog {
		
		/**
		 * ```tree
		 * user $hyoo_intern_person
		 * ```
		 */
		@ $mol_mem
		user() {
			const obj = new this.$.$hyoo_intern_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * campaign $hyoo_intern_campaign
		 * ```
		 */
		@ $mol_mem
		campaign() {
			const obj = new this.$.$hyoo_intern_campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_client
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_client()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Placeholder null
		 * ```
		 */
		Placeholder() {
			return null as any
		}
		
		/**
		 * ```tree
		 * role* null
		 * ```
		 */
		role(id: any) {
			return null as any
		}
		
		/**
		 * ```tree
		 * Menu_profile_card $hyoo_intern_person_card_profile
		 * 	title <= profile_title
		 * 	name_sub <= profile_name_sub
		 * 	person <= user
		 * ```
		 */
		@ $mol_mem
		Menu_profile_card() {
			const obj = new this.$.$hyoo_intern_person_card_profile()
			
			obj.title = () => this.profile_title()
			obj.name_sub = () => this.profile_name_sub()
			obj.person = () => this.user()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Profile $mol_page
		 * 	title <= profile_title
		 * 	body <= profile_body
		 * ```
		 */
		@ $mol_mem
		Profile() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.profile_title()
			obj.body = () => this.profile_body()
			
			return obj
		}
		
		/**
		 * ```tree
		 * menu_head /
		 * 	<= Logo
		 * 	<= Label
		 * ```
		 */
		menu_head() {
			return [
				this.Logo(),
				this.Label()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_body / <= Menu_links
		 * ```
		 */
		menu_body() {
			return [
				this.Menu_links()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_foot /
		 * 	<= Lights
		 * 	<= Online
		 * ```
		 */
		menu_foot() {
			return [
				this.Lights(),
				this.Online()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * param \section
		 * ```
		 */
		param() {
			return "section"
		}
		
		/**
		 * ```tree
		 * profile_title \Профиль
		 * ```
		 */
		profile_title() {
			return "Профиль"
		}
		
		/**
		 * ```tree
		 * profile_name_sub /
		 * ```
		 */
		profile_name_sub() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Profile_card $hyoo_intern_person_card person <= user
		 * ```
		 */
		@ $mol_mem
		Profile_card() {
			const obj = new this.$.$hyoo_intern_person_card()
			
			obj.person = () => this.user()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sign_out? null
		 * ```
		 */
		@ $mol_mem
		sign_out(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Sign_out $mol_button_minor
		 * 	title \Выйти из личного кабинета
		 * 	click? <=> sign_out?
		 * ```
		 */
		@ $mol_mem
		Sign_out() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Выйти из личного кабинета"
			obj.click = (next?: any) => this.sign_out(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * profile_body /
		 * 	<= Profile_card
		 * 	<= Sign_out
		 * ```
		 */
		profile_body() {
			return [
				this.Profile_card(),
				this.Sign_out()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Logo $mol_image uri \/hyoo/intern/logo/logo.svg
		 * ```
		 */
		@ $mol_mem
		Logo() {
			const obj = new this.$.$mol_image()
			
			obj.uri = () => "/hyoo/intern/logo/logo.svg"
			
			return obj
		}
		
		/**
		 * ```tree
		 * menu_title \Стажировка
		 * ```
		 */
		menu_title() {
			return "Стажировка"
		}
		
		/**
		 * ```tree
		 * Title $mol_paragraph title <= menu_title
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.menu_title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * descr @ \Правительство Москвы
		 * ```
		 */
		descr() {
			return this.$.$mol_locale.text( '$hyoo_intern_cabinet_descr' )
		}
		
		/**
		 * ```tree
		 * Descr $mol_paragraph title <= descr
		 * ```
		 */
		@ $mol_mem
		Descr() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.descr()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Label $mol_list rows /
		 * 	<= Title
		 * 	<= Descr
		 * ```
		 */
		@ $mol_mem
		Label() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Title(),
				this.Descr()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Online $hyoo_sync_online yard <= yard
		 * ```
		 */
		@ $mol_mem
		Online() {
			const obj = new this.$.$hyoo_sync_online()
			
			obj.yard = () => this.yard()
			
			return obj
		}
	}
	
}

