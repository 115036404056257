namespace $.$$ {

	$mol_style_define( $hyoo_intern_cabinet_admin, {

		Campaign: $hyoo_intern_cabinet_page_style,
		Profile: $hyoo_intern_cabinet_page_style,
		Statistics: $hyoo_intern_cabinet_page_style,
		Timesheet: $hyoo_intern_cabinet_page_style,
		Career_school: $hyoo_intern_cabinet_page_style,
		Tests: $hyoo_intern_cabinet_page_style,
		Championship: $hyoo_intern_cabinet_page_style,

	 } )

}
