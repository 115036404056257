namespace $ {
	export class $hyoo_intern_cabinet_admin extends $hyoo_intern_cabinet {
		
		/**
		 * ```tree
		 * profile_title \Администратор
		 * ```
		 */
		profile_title() {
			return "Администратор"
		}
		
		/**
		 * ```tree
		 * companies
		 * ```
		 */
		companies() {
			return this.campaign().companies()
		}
		
		/**
		 * ```tree
		 * managers
		 * ```
		 */
		managers() {
			return this.campaign().managers()
		}
		
		/**
		 * ```tree
		 * curators
		 * ```
		 */
		curators() {
			return this.campaign().curators()
		}
		
		/**
		 * ```tree
		 * mentors
		 * ```
		 */
		mentors() {
			return this.campaign().mentors()
		}
		
		/**
		 * ```tree
		 * candidates_on_review
		 * ```
		 */
		candidates_on_review() {
			return this.campaign().candidates_on_review()
		}
		
		/**
		 * ```tree
		 * campaign $hyoo_intern_campaign
		 * 	companies => companies
		 * 	managers => managers
		 * 	curators => curators
		 * 	mentors => mentors
		 * 	candidates_on_review => candidates_on_review
		 * ```
		 */
		@ $mol_mem
		campaign() {
			const obj = new this.$.$hyoo_intern_campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * spreads *
		 * 	profile <= Profile
		 * 	campaign <= Campaign
		 * 	curators <= Curators
		 * 	resumes <= Resumes
		 * 	candidates <= Candidates
		 * 	requested_invitations <= Requested_invitations
		 * 	companies <= Companies
		 * 	managers <= Managers
		 * 	mentors <= Mentors
		 * 	vacancies <= Vacancies
		 * 	statistics <= Statistics
		 * 	timesheet <= Timesheet
		 * 	career_school <= Career_school
		 * 	tests <= Tests
		 * 	championship <= Championship
		 * ```
		 */
		spreads() {
			return {
				profile: this.Profile(),
				campaign: this.Campaign(),
				curators: this.Curators(),
				resumes: this.Resumes(),
				candidates: this.Candidates(),
				requested_invitations: this.Requested_invitations(),
				companies: this.Companies(),
				managers: this.Managers(),
				mentors: this.Mentors(),
				vacancies: this.Vacancies(),
				statistics: this.Statistics(),
				timesheet: this.Timesheet(),
				career_school: this.Career_school(),
				tests: this.Tests(),
				championship: this.Championship()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Campaign $hyoo_intern_campaign_card campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Campaign() {
			const obj = new this.$.$hyoo_intern_campaign_card()
			
			obj.campaign = () => this.campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * add_curator? null
		 * ```
		 */
		@ $mol_mem
		add_curator(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Add_curator $mol_button_minor
		 * 	sub / <= Add_icon
		 * 	click? <=> add_curator?
		 * ```
		 */
		@ $mol_mem
		Add_curator() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.add_curator(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * add_curator_person_id? \
		 * ```
		 */
		@ $mol_mem
		add_curator_person_id(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Add_curator_person $mol_string value? <=> add_curator_person_id?
		 * ```
		 */
		@ $mol_mem
		Add_curator_person() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.add_curator_person_id(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Curators $hyoo_intern_catalog_curators
		 * 	entity_links <= curators
		 * 	menu_foot /
		 * 		<= Add_curator
		 * 		<= Add_curator_person
		 * ```
		 */
		@ $mol_mem
		Curators() {
			const obj = new this.$.$hyoo_intern_catalog_curators()
			
			obj.entity_links = () => this.curators()
			obj.menu_foot = () => [
				this.Add_curator(),
				this.Add_curator_person()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Resumes $hyoo_intern_catalog_interns
		 * 	menu_title \Заявки на стажировку (резюме)
		 * 	entity_links <= candidates_on_review
		 * ```
		 */
		@ $mol_mem
		Resumes() {
			const obj = new this.$.$hyoo_intern_catalog_interns()
			
			obj.menu_title = () => "Заявки на стажировку (резюме)"
			obj.entity_links = () => this.candidates_on_review()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Candidates $hyoo_intern_catalog_interns menu_title \Кандидаты
		 * ```
		 */
		@ $mol_mem
		Candidates() {
			const obj = new this.$.$hyoo_intern_catalog_interns()
			
			obj.menu_title = () => "Кандидаты"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Requested_invitations $hyoo_intern_catalog_vacancies menu_title \Потребность в стажерах (приглашения)
		 * ```
		 */
		@ $mol_mem
		Requested_invitations() {
			const obj = new this.$.$hyoo_intern_catalog_vacancies()
			
			obj.menu_title = () => "Потребность в стажерах (приглашения)"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Companies $hyoo_intern_catalog_companies entity_links <= companies
		 * ```
		 */
		@ $mol_mem
		Companies() {
			const obj = new this.$.$hyoo_intern_catalog_companies()
			
			obj.entity_links = () => this.companies()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Managers $hyoo_intern_catalog_managers entity_links <= managers
		 * ```
		 */
		@ $mol_mem
		Managers() {
			const obj = new this.$.$hyoo_intern_catalog_managers()
			
			obj.entity_links = () => this.managers()
			
			return obj
		}
		
		/**
		 * ```tree
		 * add_mentor? null
		 * ```
		 */
		@ $mol_mem
		add_mentor(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Add_mentor $mol_button_minor
		 * 	sub / <= Add_icon
		 * 	click? <=> add_mentor?
		 * ```
		 */
		@ $mol_mem
		Add_mentor() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.add_mentor(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * add_mentor_person_id? \
		 * ```
		 */
		@ $mol_mem
		add_mentor_person_id(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Add_mentor_person $mol_string value? <=> add_mentor_person_id?
		 * ```
		 */
		@ $mol_mem
		Add_mentor_person() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.add_mentor_person_id(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Mentors $hyoo_intern_catalog_mentors
		 * 	entity_links <= mentors
		 * 	menu_foot /
		 * 		<= Add_mentor
		 * 		<= Add_mentor_person
		 * ```
		 */
		@ $mol_mem
		Mentors() {
			const obj = new this.$.$hyoo_intern_catalog_mentors()
			
			obj.entity_links = () => this.mentors()
			obj.menu_foot = () => [
				this.Add_mentor(),
				this.Add_mentor_person()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Vacancies $hyoo_intern_catalog_vacancies
		 * ```
		 */
		@ $mol_mem
		Vacancies() {
			const obj = new this.$.$hyoo_intern_catalog_vacancies()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Statistics $mol_page title \Статистика
		 * ```
		 */
		@ $mol_mem
		Statistics() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Статистика"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Timesheet $mol_page title \Табель рабочего времени
		 * ```
		 */
		@ $mol_mem
		Timesheet() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Табель рабочего времени"
			
			return obj
		}
		
		/**
		 * ```tree
		 * career_school_text \
		 * 	\[Ссылка 1](https://talent.mos.ru)
		 * 	\[Ссылка 2](https://talent.mos.ru)
		 * ```
		 */
		career_school_text() {
			return "[Ссылка 1](https://talent.mos.ru)\n[Ссылка 2](https://talent.mos.ru)"
		}
		
		/**
		 * ```tree
		 * Career_school_text $mol_textarea value <= career_school_text
		 * ```
		 */
		@ $mol_mem
		Career_school_text() {
			const obj = new this.$.$mol_textarea()
			
			obj.value = () => this.career_school_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Career_school $mol_page
		 * 	title \Карьерная школа
		 * 	body / <= Career_school_text
		 * ```
		 */
		@ $mol_mem
		Career_school() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Карьерная школа"
			obj.body = () => [
				this.Career_school_text()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * tests_text \
		 * 	\[Прохождение трека Русский язык](https://talent.mos.ru)
		 * 	\[Ссылка 2](https://talent.mos.ru)
		 * 	\[Прохождение трека Карьерный Шаг в Правительство Москвы](https://talent.mos.ru)
		 * 	\[Прохождение трека Анализ информации](https://talent.mos.ru)
		 * 	\Один трек на выбор:
		 * 	\[Прохождение трека Word](https://talent.mos.ru)
		 * 	\[Прохождение трека Excel](https://talent.mos.ru)
		 * 	\[Прохождение трека PowerPoint](https://talent.mos.ru)
		 * ```
		 */
		tests_text() {
			return "[Прохождение трека Русский язык](https://talent.mos.ru)\n[Ссылка 2](https://talent.mos.ru)\n[Прохождение трека Карьерный Шаг в Правительство Москвы](https://talent.mos.ru)\n[Прохождение трека Анализ информации](https://talent.mos.ru)\nОдин трек на выбор:\n[Прохождение трека Word](https://talent.mos.ru)\n[Прохождение трека Excel](https://talent.mos.ru)\n[Прохождение трека PowerPoint](https://talent.mos.ru)"
		}
		
		/**
		 * ```tree
		 * Tests_text $mol_textarea value <= tests_text
		 * ```
		 */
		@ $mol_mem
		Tests_text() {
			const obj = new this.$.$mol_textarea()
			
			obj.value = () => this.tests_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tests $mol_page
		 * 	title \Этап тестирования
		 * 	body / <= Tests_text
		 * ```
		 */
		@ $mol_mem
		Tests() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Этап тестирования"
			obj.body = () => [
				this.Tests_text()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * championship_text \
		 * 	\Защити свой проект на кейс-чемпионате.
		 * ```
		 */
		championship_text() {
			return "Защити свой проект на кейс-чемпионате."
		}
		
		/**
		 * ```tree
		 * Championship_text $mol_textarea value <= championship_text
		 * ```
		 */
		@ $mol_mem
		Championship_text() {
			const obj = new this.$.$mol_textarea()
			
			obj.value = () => this.championship_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Championship $mol_page
		 * 	title \Кейс-чемпионат
		 * 	body / <= Championship_text
		 * ```
		 */
		@ $mol_mem
		Championship() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Кейс-чемпионат"
			obj.body = () => [
				this.Championship_text()
			] as readonly any[]
			
			return obj
		}
	}
	
}

