namespace $ {
	export class $hyoo_intern_person_card_profile extends $mol_view {
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.person().id()
		}
		
		/**
		 * ```tree
		 * name
		 * ```
		 */
		name() {
			return this.person().name()
		}
		
		/**
		 * ```tree
		 * avatar_node
		 * ```
		 */
		avatar_node() {
			return this.person().avatar_node()
		}
		
		/**
		 * ```tree
		 * person $hyoo_intern_person
		 * 	id => id
		 * 	name => name
		 * 	avatar_node => avatar_node
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_intern_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Avatar
		 * 	<= Info
		 * ```
		 */
		sub() {
			return [
				this.Avatar(),
				this.Info()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Avatar $hyoo_intern_person_avatar blob <= avatar_node
		 * ```
		 */
		@ $mol_mem
		Avatar() {
			const obj = new this.$.$hyoo_intern_person_avatar()
			
			obj.blob = () => this.avatar_node()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title \
		 * ```
		 */
		title() {
			return ""
		}
		
		/**
		 * ```tree
		 * Title $mol_paragraph title <= title
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_paragraph title <= name
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * name_sub /
		 * ```
		 */
		name_sub() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Name_sub $mol_paragraph sub <= name_sub
		 * ```
		 */
		@ $mol_mem
		Name_sub() {
			const obj = new this.$.$mol_paragraph()
			
			obj.sub = () => this.name_sub()
			
			return obj
		}
		
		/**
		 * ```tree
		 * info /
		 * 	<= Title
		 * 	<= Name
		 * 	<= Name_sub
		 * ```
		 */
		info() {
			return [
				this.Title(),
				this.Name(),
				this.Name_sub()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Info $mol_list rows <= info
		 * ```
		 */
		@ $mol_mem
		Info() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.info()
			
			return obj
		}
	}
	
}

