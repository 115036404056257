namespace $.$$ {

	export class $hyoo_intern_person_card extends $.$hyoo_intern_person_card {

		sub() {
			return this.person() ?
				super.sub() :
				[]
		}

	}

}
