namespace $ {
	export class $hyoo_intern_catalog_mentors extends $hyoo_intern_catalog_entities {
		
		/**
		 * ```tree
		 * menu_title \Наставники
		 * ```
		 */
		menu_title() {
			return "Наставники"
		}
		
		/**
		 * ```tree
		 * Card_view* $hyoo_intern_person_card
		 * 	disabled true
		 * 	person <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_view(id: any) {
			const obj = new this.$.$hyoo_intern_person_card()
			
			obj.disabled = () => true
			obj.person = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card_edit* $hyoo_intern_person_card person <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_edit(id: any) {
			const obj = new this.$.$hyoo_intern_person_card()
			
			obj.person = () => this.item(id)
			
			return obj
		}
	}
	
}

