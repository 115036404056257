namespace $ {
	export class $hyoo_intern_campaign_card extends $mol_page {
		
		/**
		 * ```tree
		 * campaign $hyoo_intern_campaign
		 * ```
		 */
		@ $mol_mem
		campaign() {
			const obj = new this.$.$hyoo_intern_campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title \Кампания стажировки
		 * ```
		 */
		title() {
			return "Кампания стажировки"
		}
		
		/**
		 * ```tree
		 * body / <= Campaign_form
		 * ```
		 */
		body() {
			return [
				this.Campaign_form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Name $mol_string value? <=> value_str*name?
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("name", next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name \Наименование
		 * 	Content <= Name
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Наименование"
			obj.Content = () => this.Name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * value_str*?
		 * ```
		 */
		value_str(id: any, next?: any) {
			return this.Campaign_form().value_str(id, next)
		}
		
		/**
		 * ```tree
		 * Campaign_form $mol_form_draft
		 * 	model <= campaign
		 * 	value_str*? => value_str*?
		 * 	form_fields / <= Name_field
		 * ```
		 */
		@ $mol_mem
		Campaign_form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.model = () => this.campaign()
			obj.form_fields = () => [
				this.Name_field()
			] as readonly any[]
			
			return obj
		}
	}
	
}

