namespace $.$$ {

	const { per, rem } = $mol_style_unit

	$mol_style_define( $hyoo_intern_person_avatar, {

		width: rem(3),
		height: rem(3),
		objectFit: 'scale-down',

		flex: {
			shrink: 0,
		},
		
	} )

}
