namespace $.$$ {

	$mol_style_define( $hyoo_intern_cabinet_manager, {

		Profile: $hyoo_intern_cabinet_page_style,
		Company_page: $hyoo_intern_cabinet_page_style,

	 } )

}
