namespace $ {
	export class $mol_form_draft extends $mol_form {
		
		/**
		 * ```tree
		 * model $mol_object2
		 * ```
		 */
		@ $mol_mem
		model() {
			const obj = new this.$.$mol_object2()
			
			return obj
		}
	}
	
}

