namespace $ {
	export class $hyoo_intern_cabinet_intern extends $hyoo_intern_cabinet {
		
		/**
		 * ```tree
		 * profile_title \Профиль стажера
		 * ```
		 */
		profile_title() {
			return "Профиль стажера"
		}
		
		/**
		 * ```tree
		 * profile_name_sub / <= Stage_title
		 * ```
		 */
		profile_name_sub() {
			return [
				this.Stage_title()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * stage? 3
		 * ```
		 */
		@ $mol_mem
		stage(next?: any) {
			if ( next !== undefined ) return next as never
			return 3
		}
		
		/**
		 * ```tree
		 * profile_body /
		 * 	<= Profile_card
		 * 	<= Sign_out
		 * 	<= Intern_stage_field
		 * ```
		 */
		profile_body() {
			return [
				this.Profile_card(),
				this.Sign_out(),
				this.Intern_stage_field()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * stage_spreads *
		 * 	1 *
		 * 		profile <= Profile
		 * 		resume <= Resume
		 * 	2 *
		 * 		profile <= Profile
		 * 		resume <= Resume
		 * 		career_school <= Career_school
		 * 	3 *
		 * 		profile <= Profile
		 * 		resume <= Resume
		 * 		career_school <= Career_school
		 * 		tests <= Tests
		 * 	4 *
		 * 		profile <= Profile
		 * 		resume <= Resume
		 * 		championship <= Championship
		 * 		invitations <= Intern_invitations
		 * 		responses <= Responses
		 * 	5 *
		 * 		profile <= Profile
		 * 		resume <= Resume
		 * 		internship <= Internship
		 * 		timesheet <= Timesheet
		 * ```
		 */
		stage_spreads() {
			return {
				1: {
					profile: this.Profile(),
					resume: this.Resume()
				} as Record< string, any >,
				2: {
					profile: this.Profile(),
					resume: this.Resume(),
					career_school: this.Career_school()
				} as Record< string, any >,
				3: {
					profile: this.Profile(),
					resume: this.Resume(),
					career_school: this.Career_school(),
					tests: this.Tests()
				} as Record< string, any >,
				4: {
					profile: this.Profile(),
					resume: this.Resume(),
					championship: this.Championship(),
					invitations: this.Intern_invitations(),
					responses: this.Responses()
				} as Record< string, any >,
				5: {
					profile: this.Profile(),
					resume: this.Resume(),
					internship: this.Internship(),
					timesheet: this.Timesheet()
				} as Record< string, any >
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * stage_title \1 этап стажировки
		 * ```
		 */
		stage_title() {
			return "1 этап стажировки"
		}
		
		/**
		 * ```tree
		 * Stage_title $mol_paragraph title <= stage_title
		 * ```
		 */
		@ $mol_mem
		Stage_title() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.stage_title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Intern_stage_number $mol_number
		 * 	value_min 1
		 * 	value_max 5
		 * 	value? <=> stage?
		 * ```
		 */
		@ $mol_mem
		Intern_stage_number() {
			const obj = new this.$.$mol_number()
			
			obj.value_min = () => 1
			obj.value_max = () => 5
			obj.value = (next?: any) => this.stage(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Intern_stage_field $mol_form_field
		 * 	name \Demo intern_stage
		 * 	control <= Intern_stage_number
		 * ```
		 */
		@ $mol_mem
		Intern_stage_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Demo intern_stage"
			obj.control = () => this.Intern_stage_number()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Resume_card $hyoo_intern_resume_card person <= user
		 * ```
		 */
		@ $mol_mem
		Resume_card() {
			const obj = new this.$.$hyoo_intern_resume_card()
			
			obj.person = () => this.user()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Resume $mol_page
		 * 	title \Анкета
		 * 	body / <= Resume_card
		 * ```
		 */
		@ $mol_mem
		Resume() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Анкета"
			obj.body = () => [
				this.Resume_card()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * career_school_text \
		 * 	\[Ссылка 1](https://talent.mos.ru)
		 * 	\[Ссылка 2](https://talent.mos.ru)
		 * ```
		 */
		career_school_text() {
			return "[Ссылка 1](https://talent.mos.ru)\n[Ссылка 2](https://talent.mos.ru)"
		}
		
		/**
		 * ```tree
		 * Career_school_text $mol_text text <= career_school_text
		 * ```
		 */
		@ $mol_mem
		Career_school_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.career_school_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Career_school $mol_page
		 * 	title \Карьерная школа
		 * 	body / <= Career_school_text
		 * ```
		 */
		@ $mol_mem
		Career_school() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Карьерная школа"
			obj.body = () => [
				this.Career_school_text()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * tests_text \
		 * 	\[Прохождение трека Русский язык](https://talent.mos.ru)
		 * 	\[Ссылка 2](https://talent.mos.ru)
		 * 	\[Прохождение трека Карьерный Шаг в Правительство Москвы](https://talent.mos.ru)
		 * 	\[Прохождение трека Анализ информации](https://talent.mos.ru)
		 * 	\Один трек на выбор:
		 * 	\[Прохождение трека Word](https://talent.mos.ru)
		 * 	\[Прохождение трека Excel](https://talent.mos.ru)
		 * 	\[Прохождение трека PowerPoint](https://talent.mos.ru)
		 * ```
		 */
		tests_text() {
			return "[Прохождение трека Русский язык](https://talent.mos.ru)\n[Ссылка 2](https://talent.mos.ru)\n[Прохождение трека Карьерный Шаг в Правительство Москвы](https://talent.mos.ru)\n[Прохождение трека Анализ информации](https://talent.mos.ru)\nОдин трек на выбор:\n[Прохождение трека Word](https://talent.mos.ru)\n[Прохождение трека Excel](https://talent.mos.ru)\n[Прохождение трека PowerPoint](https://talent.mos.ru)"
		}
		
		/**
		 * ```tree
		 * Tests_text $mol_text text <= tests_text
		 * ```
		 */
		@ $mol_mem
		Tests_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.tests_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tests $mol_page
		 * 	title \Тестирование
		 * 	body / <= Tests_text
		 * ```
		 */
		@ $mol_mem
		Tests() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Тестирование"
			obj.body = () => [
				this.Tests_text()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * championship_text \
		 * 	\Защити свой проект на кейс-чемпионате.
		 * ```
		 */
		championship_text() {
			return "Защити свой проект на кейс-чемпионате."
		}
		
		/**
		 * ```tree
		 * Championship_text $mol_text text <= championship_text
		 * ```
		 */
		@ $mol_mem
		Championship_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.championship_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Championship $mol_page
		 * 	title \Кейс-чемпионат
		 * 	body / <= Championship_text
		 * ```
		 */
		@ $mol_mem
		Championship() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Кейс-чемпионат"
			obj.body = () => [
				this.Championship_text()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Intern_invitations $hyoo_intern_catalog_vacancies
		 * ```
		 */
		@ $mol_mem
		Intern_invitations() {
			const obj = new this.$.$hyoo_intern_catalog_vacancies()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Responses $mol_page title \Мои отклики
		 * ```
		 */
		@ $mol_mem
		Responses() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Мои отклики"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Vacancy $hyoo_intern_vacancy_card
		 * ```
		 */
		@ $mol_mem
		Vacancy() {
			const obj = new this.$.$hyoo_intern_vacancy_card()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Internship $mol_page
		 * 	title \Моя стажировка
		 * 	body / <= Vacancy
		 * ```
		 */
		@ $mol_mem
		Internship() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Моя стажировка"
			obj.body = () => [
				this.Vacancy()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Timesheet $mol_page title \Табель рабочего времени
		 * ```
		 */
		@ $mol_mem
		Timesheet() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Табель рабочего времени"
			
			return obj
		}
	}
	
}

