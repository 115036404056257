namespace $.$$ {

	export class $hyoo_intern_cabinet_mentor extends $.$hyoo_intern_cabinet_mentor {

		@ $mol_mem
		vacancy() {
			return this.user().mentor_vacancy()
		}

	}

}
