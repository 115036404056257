namespace $ {
	export class $hyoo_intern_resume_card extends $mol_view {
		
		/**
		 * ```tree
		 * disabled false
		 * ```
		 */
		disabled() {
			return false
		}
		
		/**
		 * ```tree
		 * person $hyoo_intern_person
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_intern_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * submit? null
		 * ```
		 */
		@ $mol_mem
		submit(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * sub / <= Resume_form
		 * ```
		 */
		sub() {
			return [
				this.Resume_form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * name_second_bid \
		 * ```
		 */
		name_second_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * name_second? \
		 * ```
		 */
		@ $mol_mem
		name_second(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Name_second_control $mol_string
		 * 	hint \Иванов
		 * 	value? <=> name_second?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Name_second_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => "Иванов"
			obj.value = (next?: any) => this.name_second(next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_second_field $mol_form_field
		 * 	name \Фамилия
		 * 	bid <= name_second_bid
		 * 	Content <= Name_second_control
		 * ```
		 */
		@ $mol_mem
		Name_second_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Фамилия"
			obj.bid = () => this.name_second_bid()
			obj.Content = () => this.Name_second_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * name_first_bid \
		 * ```
		 */
		name_first_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * name_first? \
		 * ```
		 */
		@ $mol_mem
		name_first(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Name_first_control $mol_string
		 * 	hint \Иван
		 * 	value? <=> name_first?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Name_first_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => "Иван"
			obj.value = (next?: any) => this.name_first(next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_first_field $mol_form_field
		 * 	name \Имя
		 * 	bid <= name_first_bid
		 * 	Content <= Name_first_control
		 * ```
		 */
		@ $mol_mem
		Name_first_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Имя"
			obj.bid = () => this.name_first_bid()
			obj.Content = () => this.Name_first_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * name_patronymic_bid \
		 * ```
		 */
		name_patronymic_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * name_patronymic? \
		 * ```
		 */
		@ $mol_mem
		name_patronymic(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Name_patronymic_control $mol_string
		 * 	hint \Иванович
		 * 	value? <=> name_patronymic?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Name_patronymic_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => "Иванович"
			obj.value = (next?: any) => this.name_patronymic(next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_patronymic_field $mol_form_field
		 * 	name \Отчество
		 * 	bid <= name_patronymic_bid
		 * 	Content <= Name_patronymic_control
		 * ```
		 */
		@ $mol_mem
		Name_patronymic_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Отчество"
			obj.bid = () => this.name_patronymic_bid()
			obj.Content = () => this.Name_patronymic_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Names $mol_form_group sub /
		 * 	<= Name_second_field
		 * 	<= Name_first_field
		 * 	<= Name_patronymic_field
		 * ```
		 */
		@ $mol_mem
		Names() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Name_second_field(),
				this.Name_first_field(),
				this.Name_patronymic_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * birthdate_value? \2000-01-01
		 * ```
		 */
		@ $mol_mem
		birthdate_value(next?: any) {
			if ( next !== undefined ) return next as never
			return "2000-01-01"
		}
		
		/**
		 * ```tree
		 * enabled true
		 * ```
		 */
		enabled() {
			return true
		}
		
		/**
		 * ```tree
		 * Birthdate_control $mol_date
		 * 	value? <=> birthdate_value?
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Birthdate_control() {
			const obj = new this.$.$mol_date()
			
			obj.value = (next?: any) => this.birthdate_value(next)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Birthdate_field $mol_form_field
		 * 	name \Дата рождения
		 * 	Content <= Birthdate_control
		 * ```
		 */
		@ $mol_mem
		Birthdate_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Дата рождения"
			obj.Content = () => this.Birthdate_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * citizenship? \russia
		 * ```
		 */
		@ $mol_mem
		citizenship(next?: any) {
			if ( next !== undefined ) return next as never
			return "russia"
		}
		
		/**
		 * ```tree
		 * citizenship_dict * russia \РФ
		 * ```
		 */
		citizenship_dict() {
			return {
				russia: "РФ"
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Citizenship_control $mol_select
		 * 	value? <=> citizenship?
		 * 	dictionary <= citizenship_dict
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Citizenship_control() {
			const obj = new this.$.$mol_select()
			
			obj.value = (next?: any) => this.citizenship(next)
			obj.dictionary = () => this.citizenship_dict()
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Citizenship_field $mol_form_field
		 * 	name \Гражданство
		 * 	Content <= Citizenship_control
		 * ```
		 */
		@ $mol_mem
		Citizenship_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Гражданство"
			obj.Content = () => this.Citizenship_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * city? \Москва
		 * ```
		 */
		@ $mol_mem
		city(next?: any) {
			if ( next !== undefined ) return next as never
			return "Москва"
		}
		
		/**
		 * ```tree
		 * City_control $mol_string
		 * 	value? <=> city?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		City_control() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.city(next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * City_field $mol_form_field
		 * 	name \Место проживания
		 * 	Content <= City_control
		 * ```
		 */
		@ $mol_mem
		City_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Место проживания"
			obj.Content = () => this.City_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Bdate_citizenship_city $mol_form_group sub /
		 * 	<= Birthdate_field
		 * 	<= Citizenship_field
		 * 	<= City_field
		 * ```
		 */
		@ $mol_mem
		Bdate_citizenship_city() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Birthdate_field(),
				this.Citizenship_field(),
				this.City_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * institutions_field_name @ \Institutions
		 * ```
		 */
		institutions_field_name() {
			return this.$.$mol_locale.text( '$hyoo_intern_resume_card_institutions_field_name' )
		}
		
		/**
		 * ```tree
		 * Institution_add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Institution_add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution_add? null
		 * ```
		 */
		@ $mol_mem
		institution_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Institution_add $mol_button_minor
		 * 	sub / <= Institution_add_icon
		 * 	click? <=> institution_add?
		 * ```
		 */
		@ $mol_mem
		Institution_add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Institution_add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.institution_add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * specialty*? \
		 * ```
		 */
		@ $mol_mem_key
		specialty(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Specialty_control* $mol_string value? <=> specialty*?
		 * ```
		 */
		@ $mol_mem_key
		Specialty_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.specialty(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Specialty_field* $mol_form_field
		 * 	name @ \Specialty
		 * 	Content <= Specialty_control*
		 * ```
		 */
		@ $mol_mem_key
		Specialty_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Specialty_field_name' )
			obj.Content = () => this.Specialty_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * degree*? \
		 * ```
		 */
		@ $mol_mem_key
		degree(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Degree_control* $mol_string value? <=> degree*?
		 * ```
		 */
		@ $mol_mem_key
		Degree_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.degree(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Degree_field* $mol_form_field
		 * 	name @ \Degree
		 * 	Content <= Degree_control*
		 * ```
		 */
		@ $mol_mem_key
		Degree_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Degree_field_name' )
			obj.Content = () => this.Degree_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Student_group* $mol_form_group sub /
		 * 	<= Specialty_field*
		 * 	<= Degree_field*
		 * ```
		 */
		@ $mol_mem_key
		Student_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Specialty_field(id),
				this.Degree_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * date_finish*? null
		 * ```
		 */
		@ $mol_mem_key
		date_finish(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Date_finish_control* $mol_date value_moment? <=> date_finish*?
		 * ```
		 */
		@ $mol_mem_key
		Date_finish_control(id: any) {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (next?: any) => this.date_finish(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_finish* $mol_form_field
		 * 	name @ \Graduation date
		 * 	Content <= Date_finish_control*
		 * ```
		 */
		@ $mol_mem_key
		Date_finish(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Date_finish_name' )
			obj.Content = () => this.Date_finish_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution*? \
		 * ```
		 */
		@ $mol_mem_key
		institution(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Institution_control* $mol_string value? <=> institution*?
		 * ```
		 */
		@ $mol_mem_key
		Institution_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.institution(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_field* $mol_form_field
		 * 	name @ \Institution
		 * 	Content <= Institution_control*
		 * ```
		 */
		@ $mol_mem_key
		Institution_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Institution_field_name' )
			obj.Content = () => this.Institution_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * department*? \
		 * ```
		 */
		@ $mol_mem_key
		department(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Department_control* $mol_string value? <=> department*?
		 * ```
		 */
		@ $mol_mem_key
		Department_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.department(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Department_field* $mol_form_field
		 * 	name @ \Department
		 * 	Content <= Department_control*
		 * ```
		 */
		@ $mol_mem_key
		Department_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Department_field_name' )
			obj.Content = () => this.Department_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_group* $mol_form_group sub /
		 * 	<= Institution_field*
		 * 	<= Department_field*
		 * ```
		 */
		@ $mol_mem_key
		Institution_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Institution_field(id),
				this.Department_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_drop_icon* $mol_icon_delete
		 * ```
		 */
		@ $mol_mem_key
		Institution_drop_icon(id: any) {
			const obj = new this.$.$mol_icon_delete()
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution_drop*? null
		 * ```
		 */
		@ $mol_mem_key
		institution_drop(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Institution_drop* $mol_button_minor
		 * 	sub / <= Institution_drop_icon*
		 * 	click? <=> institution_drop*?
		 * ```
		 */
		@ $mol_mem_key
		Institution_drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Institution_drop_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.institution_drop(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_drop_row* $mol_row sub / <= Institution_drop*
		 * ```
		 */
		@ $mol_mem_key
		Institution_drop_row(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Institution_drop(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institution_form*0 $mol_list rows /
		 * 	<= Student_group*
		 * 	<= Date_finish*
		 * 	<= Institution_group*
		 * 	<= Institution_drop_row*
		 * ```
		 */
		@ $mol_mem_key
		Institution_form(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Student_group(id),
				this.Date_finish(id),
				this.Institution_group(id),
				this.Institution_drop_row(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * institution_rows / <= Institution_form*0
		 * ```
		 */
		institution_rows() {
			return [
				this.Institution_form("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Institution_content $mol_list rows <= institution_rows
		 * ```
		 */
		@ $mol_mem
		Institution_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.institution_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Institutions_field $mol_form_field
		 * 	label /
		 * 		<= institutions_field_name
		 * 		<= Institution_add
		 * 	Content <= Institution_content
		 * ```
		 */
		@ $mol_mem
		Institutions_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.label = () => [
				this.institutions_field_name(),
				this.Institution_add()
			] as readonly any[]
			obj.Content = () => this.Institution_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_status? \
		 * ```
		 */
		@ $mol_mem
		job_status(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Job_status_control $mol_switch
		 * 	value? <=> job_status?
		 * 	options *
		 * 		student @ \Student
		 * 		working_for_hire @ \Working for hire
		 * 		self_employed @ \Self-employed
		 * 		unemployed @ \Unemployed
		 * ```
		 */
		@ $mol_mem
		Job_status_control() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (next?: any) => this.job_status(next)
			obj.options = () => ({
				student: this.$.$mol_locale.text( '$hyoo_intern_resume_card_Job_status_control_options_student' ),
				working_for_hire: this.$.$mol_locale.text( '$hyoo_intern_resume_card_Job_status_control_options_working_for_hire' ),
				self_employed: this.$.$mol_locale.text( '$hyoo_intern_resume_card_Job_status_control_options_self_employed' ),
				unemployed: this.$.$mol_locale.text( '$hyoo_intern_resume_card_Job_status_control_options_unemployed' )
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_status_field $mol_form_field
		 * 	name @ \Job status
		 * 	Content <= Job_status_control
		 * ```
		 */
		@ $mol_mem
		Job_status_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Job_status_field_name' )
			obj.Content = () => this.Job_status_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Skills_field_name $mol_paragraph title @ \Skills
		 * ```
		 */
		@ $mol_mem
		Skills_field_name() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Skills_field_name_title' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * name <= Skills_field_name
		 * ```
		 */
		name() {
			return this.Skills_field_name()
		}
		
		/**
		 * ```tree
		 * skills? /
		 * ```
		 */
		@ $mol_mem
		skills(next?: any) {
			if ( next !== undefined ) return next as never
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * skills_dict *
		 * 	programming @ \Programming
		 * 	design @ \Design
		 * 	managment @ \Managment
		 * ```
		 */
		skills_dict() {
			return {
				programming: this.$.$mol_locale.text( '$hyoo_intern_resume_card_skills_dict_programming' ),
				design: this.$.$mol_locale.text( '$hyoo_intern_resume_card_skills_dict_design' ),
				managment: this.$.$mol_locale.text( '$hyoo_intern_resume_card_skills_dict_managment' )
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Skills_control $mol_select_list
		 * 	value? <=> skills?
		 * 	dictionary <= skills_dict
		 * ```
		 */
		@ $mol_mem
		Skills_control() {
			const obj = new this.$.$mol_select_list()
			
			obj.value = (next?: any) => this.skills(next)
			obj.dictionary = () => this.skills_dict()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Skills_field $mol_form_field label /
		 * 	<= name
		 * 	<= Skills_control
		 * ```
		 */
		@ $mol_mem
		Skills_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.label = () => [
				this.name(),
				this.Skills_control()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * jobs_field_name @ \Experience
		 * ```
		 */
		jobs_field_name() {
			return this.$.$mol_locale.text( '$hyoo_intern_resume_card_jobs_field_name' )
		}
		
		/**
		 * ```tree
		 * Job_add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Job_add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_add? null
		 * ```
		 */
		@ $mol_mem
		job_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Job_add $mol_button_minor
		 * 	sub / <= Job_add_icon
		 * 	click? <=> job_add?
		 * ```
		 */
		@ $mol_mem
		Job_add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Job_add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.job_add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * position*? \
		 * ```
		 */
		@ $mol_mem_key
		position(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Position_control* $mol_string value? <=> position*?
		 * ```
		 */
		@ $mol_mem_key
		Position_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.position(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Position_field* $mol_form_field
		 * 	name @ \Position
		 * 	Content <= Position_control*
		 * ```
		 */
		@ $mol_mem_key
		Position_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Position_field_name' )
			obj.Content = () => this.Position_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Employer_group* $mol_form_group sub / <= Position_field*
		 * ```
		 */
		@ $mol_mem_key
		Employer_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Position_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * date_start*? null
		 * ```
		 */
		@ $mol_mem_key
		date_start(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Date_start_control* $mol_date value_moment? <=> date_start*?
		 * ```
		 */
		@ $mol_mem_key
		Date_start_control(id: any) {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (next?: any) => this.date_start(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_start_field* $mol_form_field
		 * 	name @ \Start date
		 * 	Content <= Date_start_control*
		 * ```
		 */
		@ $mol_mem_key
		Date_start_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Date_start_field_name' )
			obj.Content = () => this.Date_start_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * date_end*? null
		 * ```
		 */
		@ $mol_mem_key
		date_end(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Date_end_control* $mol_date value_moment? <=> date_end*?
		 * ```
		 */
		@ $mol_mem_key
		Date_end_control(id: any) {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (next?: any) => this.date_end(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * present*? false
		 * ```
		 */
		@ $mol_mem_key
		present(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Up_to_present_control* $mol_check_box
		 * 	title @ \Up to present
		 * 	checked? <=> present*?
		 * ```
		 */
		@ $mol_mem_key
		Up_to_present_control(id: any) {
			const obj = new this.$.$mol_check_box()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Up_to_present_control_title' )
			obj.checked = (next?: any) => this.present(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_end_content* $mol_view sub /
		 * 	<= Date_end_control*
		 * 	<= Up_to_present_control*
		 * ```
		 */
		@ $mol_mem_key
		Date_end_content(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Date_end_control(id),
				this.Up_to_present_control(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_end_field* $mol_form_field
		 * 	name @ \End date
		 * 	Content <= Date_end_content*
		 * ```
		 */
		@ $mol_mem_key
		Date_end_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Date_end_field_name' )
			obj.Content = () => this.Date_end_content(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Date_group* $mol_form_group sub /
		 * 	<= Date_start_field*
		 * 	<= Date_end_field*
		 * ```
		 */
		@ $mol_mem_key
		Date_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Date_start_field(id),
				this.Date_end_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * company*? \
		 * ```
		 */
		@ $mol_mem_key
		company(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Company_control* $mol_string value? <=> company*?
		 * ```
		 */
		@ $mol_mem_key
		Company_control(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.company(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_field* $mol_form_field
		 * 	name @ \Company
		 * 	Content <= Company_control*
		 * ```
		 */
		@ $mol_mem_key
		Company_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Company_field_name' )
			obj.Content = () => this.Company_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * industry*? \
		 * ```
		 */
		@ $mol_mem_key
		industry(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Industry_contrl* $mol_string value? <=> industry*?
		 * ```
		 */
		@ $mol_mem_key
		Industry_contrl(id: any) {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.industry(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Industry_field* $mol_form_field
		 * 	name @ \Industry
		 * 	Content <= Industry_contrl*
		 * ```
		 */
		@ $mol_mem_key
		Industry_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Industry_field_name' )
			obj.Content = () => this.Industry_contrl(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_group* $mol_form_group sub /
		 * 	<= Company_field*
		 * 	<= Industry_field*
		 * ```
		 */
		@ $mol_mem_key
		Company_group(id: any) {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Company_field(id),
				this.Industry_field(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * functions*? \
		 * ```
		 */
		@ $mol_mem_key
		functions(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Functions_control* $mol_textarea
		 * 	hint @ \Your job functions
		 * 	value? <=> functions*?
		 * ```
		 */
		@ $mol_mem_key
		Functions_control(id: any) {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Functions_control_hint' )
			obj.value = (next?: any) => this.functions(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Functions_field* $mol_form_field
		 * 	name @ \Functions
		 * 	Content <= Functions_control*
		 * ```
		 */
		@ $mol_mem_key
		Functions_field(id: any) {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Functions_field_name' )
			obj.Content = () => this.Functions_control(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_drop_icon* $mol_icon_delete
		 * ```
		 */
		@ $mol_mem_key
		Job_drop_icon(id: any) {
			const obj = new this.$.$mol_icon_delete()
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_drop*? null
		 * ```
		 */
		@ $mol_mem_key
		job_drop(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Job_drop* $mol_button_minor
		 * 	sub / <= Job_drop_icon*
		 * 	click? <=> job_drop*?
		 * ```
		 */
		@ $mol_mem_key
		Job_drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Job_drop_icon(id)
			] as readonly any[]
			obj.click = (next?: any) => this.job_drop(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_drop_row* $mol_row sub / <= Job_drop*
		 * ```
		 */
		@ $mol_mem_key
		Job_drop_row(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Job_drop(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Job_form*0 $mol_list rows /
		 * 	<= Employer_group*
		 * 	<= Date_group*
		 * 	<= Company_group*
		 * 	<= Functions_field*
		 * 	<= Job_drop_row*
		 * ```
		 */
		@ $mol_mem_key
		Job_form(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Employer_group(id),
				this.Date_group(id),
				this.Company_group(id),
				this.Functions_field(id),
				this.Job_drop_row(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * job_rows / <= Job_form*0
		 * ```
		 */
		job_rows() {
			return [
				this.Job_form("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Jobs_content $mol_list rows <= job_rows
		 * ```
		 */
		@ $mol_mem
		Jobs_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.job_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Jobs_field $mol_form_field
		 * 	label /
		 * 		<= jobs_field_name
		 * 		<= Job_add
		 * 	Content <= Jobs_content
		 * ```
		 */
		@ $mol_mem
		Jobs_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.label = () => [
				this.jobs_field_name(),
				this.Job_add()
			] as readonly any[]
			obj.Content = () => this.Jobs_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Work $mol_form
		 * 	title @ \Work
		 * 	form_fields /
		 * 		<= Job_status_field
		 * 		<= Skills_field
		 * 		<= Jobs_field
		 * ```
		 */
		@ $mol_mem
		Work() {
			const obj = new this.$.$mol_form()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Work_title' )
			obj.form_fields = () => [
				this.Job_status_field(),
				this.Skills_field(),
				this.Jobs_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * exp? \
		 * ```
		 */
		@ $mol_mem
		exp(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Exp_control $mol_textarea
		 * 	value? <=> exp?
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Exp_control() {
			const obj = new this.$.$mol_textarea()
			
			obj.value = (next?: any) => this.exp(next)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Exp_field $mol_form_field
		 * 	name \Опыт общественной/проектной деятельности/волонтерства
		 * 	Content <= Exp_control
		 * ```
		 */
		@ $mol_mem
		Exp_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Опыт общественной/проектной деятельности/волонтерства"
			obj.Content = () => this.Exp_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Phone_control $mol_phone
		 * ```
		 */
		@ $mol_mem
		Phone_control() {
			const obj = new this.$.$mol_phone()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Phone_field $mol_form_field
		 * 	name @ \Phone
		 * 	Content <= Phone_control
		 * ```
		 */
		@ $mol_mem
		Phone_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Phone_field_name' )
			obj.Content = () => this.Phone_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email_control $mol_string hint @ \my_email@mail.ru
		 * ```
		 */
		@ $mol_mem
		Email_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Email_control_hint' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email_field $mol_form_field
		 * 	name @ \Email
		 * 	Content <= Email_control
		 * ```
		 */
		@ $mol_mem
		Email_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_intern_resume_card_Email_field_name' )
			obj.Content = () => this.Email_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts $mol_form_group sub /
		 * 	<= Phone_field
		 * 	<= Email_field
		 * ```
		 */
		@ $mol_mem
		Contacts() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Phone_field(),
				this.Email_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Accept $mol_paragraph title \Нажимая кнопку «Подать заявку», ты соглашаешься на обработку персональных данных, указанных тобой в анкете (включая контактную информацию и фотографии), в рамках проекта «Карьерный портал Правительства Москвы» в соответствии с текстом согласия.
		 * ```
		 */
		@ $mol_mem
		Accept() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => "Нажимая кнопку «Подать заявку», ты соглашаешься на обработку персональных данных, указанных тобой в анкете (включая контактную информацию и фотографии), в рамках проекта «Карьерный портал Правительства Москвы» в соответствии с текстом согласия."
			
			return obj
		}
		
		/**
		 * ```tree
		 * profile_fields /
		 * 	<= Names
		 * 	<= Bdate_citizenship_city
		 * 	<= Institutions_field
		 * 	<= Work
		 * 	<= Exp_field
		 * 	<= Contacts
		 * 	<= Accept
		 * ```
		 */
		profile_fields() {
			return [
				this.Names(),
				this.Bdate_citizenship_city(),
				this.Institutions_field(),
				this.Work(),
				this.Exp_field(),
				this.Contacts(),
				this.Accept()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Resume_form $mol_form form_fields <= profile_fields
		 * ```
		 */
		@ $mol_mem
		Resume_form() {
			const obj = new this.$.$mol_form()
			
			obj.form_fields = () => this.profile_fields()
			
			return obj
		}
	}
	
}

