namespace $ {
	export class $hyoo_intern_catalog_managers extends $hyoo_intern_catalog_entities {
		
		/**
		 * ```tree
		 * menu_title \Кадровые специалисты
		 * ```
		 */
		menu_title() {
			return "Кадровые специалисты"
		}
		
		/**
		 * ```tree
		 * Card_view* $hyoo_intern_manager_card
		 * 	disabled true
		 * 	manager <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_view(id: any) {
			const obj = new this.$.$hyoo_intern_manager_card()
			
			obj.disabled = () => true
			obj.manager = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card_edit* $hyoo_intern_manager_card manager <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_edit(id: any) {
			const obj = new this.$.$hyoo_intern_manager_card()
			
			obj.manager = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * item* $hyoo_intern_manager
		 * ```
		 */
		@ $mol_mem_key
		item(id: any) {
			const obj = new this.$.$hyoo_intern_manager()
			
			return obj
		}
	}
	
}

