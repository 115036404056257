namespace $ {
	export class $hyoo_intern_cabinet_norole extends $hyoo_intern_cabinet {
		
		/**
		 * ```tree
		 * spreads *
		 * 	info <= Info
		 * 	resume_page <= Resume_page
		 * 	sign <= Sign
		 * ```
		 */
		spreads() {
			return {
				info: this.Info(),
				resume_page: this.Resume_page(),
				sign: this.Sign()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * info_text \a
		 * 	\# Стажировка в Правительстве Москвы
		 * 	\Прямо сейчас ты делаешь свой первый шаг на пути к карьере в Правительстве Москвы — участвуешь в отборе на стажировку, которая пройдет с 1 августа 2023 года по 31 января 2024 года. **Мы ждем твою заявку до 17 апреля.**
		 * 	\Стать стажерами Правительства Москвы могут студенты бакалавриата или специалитета, обучающиеся на последнем курсе, магистранты и выпускники вузов, получившие первое образование с 2016 по 2022 год.
		 * 	\**Чтобы начать заполнение заявки, перейди по ссылке ниже. Внимательно проверь, правильно ли введены все данные и особенно адрес электронной почты — на него в течение двух недель после подачи заявки придет информация о твоих следующих шагах.**
		 * 	\Анкета — это начало нашего знакомства. Мы хотим узнать о твоем образовании, опыте работы, если он есть, увлечениях или внеучебной деятельности, о навыках, которые ты приобрел во время учебы. Обязательно заполни анкету полностью перед подачей заявки — мы не сможем увидеть изменения, внесенные после отклика.
		 * 	\Желаем удачи!
		 * ```
		 */
		info_text() {
			return "# Стажировка в Правительстве Москвы\nПрямо сейчас ты делаешь свой первый шаг на пути к карьере в Правительстве Москвы — участвуешь в отборе на стажировку, которая пройдет с 1 августа 2023 года по 31 января 2024 года. **Мы ждем твою заявку до 17 апреля.**\nСтать стажерами Правительства Москвы могут студенты бакалавриата или специалитета, обучающиеся на последнем курсе, магистранты и выпускники вузов, получившие первое образование с 2016 по 2022 год.\n**Чтобы начать заполнение заявки, перейди по ссылке ниже. Внимательно проверь, правильно ли введены все данные и особенно адрес электронной почты — на него в течение двух недель после подачи заявки придет информация о твоих следующих шагах.**\nАнкета — это начало нашего знакомства. Мы хотим узнать о твоем образовании, опыте работы, если он есть, увлечениях или внеучебной деятельности, о навыках, которые ты приобрел во время учебы. Обязательно заполни анкету полностью перед подачей заявки — мы не сможем увидеть изменения, внесенные после отклика.\nЖелаем удачи!"
		}
		
		/**
		 * ```tree
		 * Info_text $mol_text text <= info_text
		 * ```
		 */
		@ $mol_mem
		Info_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.info_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * open_resume? null
		 * ```
		 */
		@ $mol_mem
		open_resume(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Resume_link $mol_button_major
		 * 	title \Перейти к заполнению заявки
		 * 	click? <=> open_resume?
		 * ```
		 */
		@ $mol_mem
		Resume_link() {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => "Перейти к заполнению заявки"
			obj.click = (next?: any) => this.open_resume(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Info $mol_page
		 * 	title \Информация для кандидатов
		 * 	body /
		 * 		<= Info_text
		 * 		<= Resume_link
		 * ```
		 */
		@ $mol_mem
		Info() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Информация для кандидатов"
			obj.body = () => [
				this.Info_text(),
				this.Resume_link()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Resume $hyoo_intern_resume_card person <= user
		 * ```
		 */
		@ $mol_mem
		Resume() {
			const obj = new this.$.$hyoo_intern_resume_card()
			
			obj.person = () => this.user()
			
			return obj
		}
		
		/**
		 * ```tree
		 * send_resume? null
		 * ```
		 */
		@ $mol_mem
		send_resume(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Send_resume $mol_button_major
		 * 	title \Подать заявку
		 * 	click? <=> send_resume?
		 * ```
		 */
		@ $mol_mem
		Send_resume() {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => "Подать заявку"
			obj.click = (next?: any) => this.send_resume(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Resume_page $mol_page
		 * 	title \Заполнить заявку
		 * 	body /
		 * 		<= Resume
		 * 		<= Send_resume
		 * ```
		 */
		@ $mol_mem
		Resume_page() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Заполнить заявку"
			obj.body = () => [
				this.Resume(),
				this.Send_resume()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sign_info $mol_text text \
		 * 	\Твой аккаунт будет автоматически привязан к браузеру на этом устройстве.
		 * 	\Перейти в личный кабинет:
		 * ```
		 */
		@ $mol_mem
		Sign_info() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "Твой аккаунт будет автоматически привязан к браузеру на этом устройстве.\nПерейти в личный кабинет:"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Open_intern $mol_button_minor
		 * 	title \Стажера
		 * 	click <= role*intern
		 * ```
		 */
		@ $mol_mem
		Open_intern() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Стажера"
			obj.click = () => this.role("intern")
			
			return obj
		}
		
		/**
		 * ```tree
		 * Open_curator $mol_button_minor
		 * 	title \Куратора
		 * 	click <= role*curator
		 * ```
		 */
		@ $mol_mem
		Open_curator() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Куратора"
			obj.click = () => this.role("curator")
			
			return obj
		}
		
		/**
		 * ```tree
		 * Open_mentor $mol_button_minor
		 * 	title \Наставника
		 * 	click <= role*mentor
		 * ```
		 */
		@ $mol_mem
		Open_mentor() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Наставника"
			obj.click = () => this.role("mentor")
			
			return obj
		}
		
		/**
		 * ```tree
		 * Open_manager $mol_button_minor
		 * 	title \Специалиста отдела кадров
		 * 	click <= role*manager
		 * ```
		 */
		@ $mol_mem
		Open_manager() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Специалиста отдела кадров"
			obj.click = () => this.role("manager")
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sign $mol_page
		 * 	title \Вход в личный кабинет
		 * 	body /
		 * 		<= Sign_info
		 * 		<= Open_intern
		 * 		<= Open_curator
		 * 		<= Open_mentor
		 * 		<= Open_manager
		 * ```
		 */
		@ $mol_mem
		Sign() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Вход в личный кабинет"
			obj.body = () => [
				this.Sign_info(),
				this.Open_intern(),
				this.Open_curator(),
				this.Open_mentor(),
				this.Open_manager()
			] as readonly any[]
			
			return obj
		}
	}
	
}

