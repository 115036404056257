namespace $ {
	export class $hyoo_intern_company_card extends $mol_view {
		
		/**
		 * ```tree
		 * disabled false
		 * ```
		 */
		disabled() {
			return false
		}
		
		/**
		 * ```tree
		 * company $hyoo_intern_company
		 * ```
		 */
		@ $mol_mem
		company() {
			const obj = new this.$.$hyoo_intern_company()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub / <= Form
		 * ```
		 */
		sub() {
			return [
				this.Form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Name $mol_string
		 * 	value? <=> value_str*name?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("name", next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name \Наименование
		 * 	Content <= Name
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Наименование"
			obj.Content = () => this.Name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Scope $mol_string
		 * 	value? <=> value_str*scope?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Scope() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("scope", next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Scope_field $mol_form_field
		 * 	name \Сфера деятельности
		 * 	Content <= Scope
		 * ```
		 */
		@ $mol_mem
		Scope_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Сфера деятельности"
			obj.Content = () => this.Scope()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Adress $mol_string
		 * 	value? <=> value_str*adress?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Adress() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("adress", next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Adress_field $mol_form_field
		 * 	name \Адрес
		 * 	Content <= Adress
		 * ```
		 */
		@ $mol_mem
		Adress_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Адрес"
			obj.Content = () => this.Adress()
			
			return obj
		}
		
		/**
		 * ```tree
		 * value_str*?
		 * ```
		 */
		value_str(id: any, next?: any) {
			return this.Form().value_str(id, next)
		}
		
		/**
		 * ```tree
		 * Form $mol_form_draft
		 * 	value_str*? => value_str*?
		 * 	model <= company
		 * 	form_fields /
		 * 		<= Name_field
		 * 		<= Scope_field
		 * 		<= Adress_field
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.model = () => this.company()
			obj.form_fields = () => [
				this.Name_field(),
				this.Scope_field(),
				this.Adress_field()
			] as readonly any[]
			
			return obj
		}
	}
	
}

