namespace $ {
	export class $hyoo_intern_vacancy_card extends $mol_view {
		
		/**
		 * ```tree
		 * vacancy $hyoo_intern_vacancy
		 * ```
		 */
		@ $mol_mem
		vacancy() {
			const obj = new this.$.$hyoo_intern_vacancy()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub / <= Form
		 * ```
		 */
		sub() {
			return [
				this.Form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Scope $mol_string
		 * ```
		 */
		@ $mol_mem
		Scope() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Scope_field $mol_form_field
		 * 	name \Сфера деятельности
		 * 	Content <= Scope
		 * ```
		 */
		@ $mol_mem
		Scope_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Сфера деятельности"
			obj.Content = () => this.Scope()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company $mol_string
		 * ```
		 */
		@ $mol_mem
		Company() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_field $mol_form_field
		 * 	name \Организация
		 * 	Content <= Company
		 * ```
		 */
		@ $mol_mem
		Company_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Организация"
			obj.Content = () => this.Company()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tasks $mol_string
		 * ```
		 */
		@ $mol_mem
		Tasks() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tasks_field $mol_form_field
		 * 	name \Обязанности и задачи
		 * 	Content <= Tasks
		 * ```
		 */
		@ $mol_mem
		Tasks_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Обязанности и задачи"
			obj.Content = () => this.Tasks()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Requirements $mol_string
		 * ```
		 */
		@ $mol_mem
		Requirements() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Requirements_field $mol_form_field
		 * 	name \Требования
		 * 	Content <= Requirements
		 * ```
		 */
		@ $mol_mem
		Requirements_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Требования"
			obj.Content = () => this.Requirements()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tests $mol_string
		 * ```
		 */
		@ $mol_mem
		Tests() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tests_field $mol_form_field
		 * 	name \Тестовые задания
		 * 	Content <= Tests
		 * ```
		 */
		@ $mol_mem
		Tests_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Тестовые задания"
			obj.Content = () => this.Tests()
			
			return obj
		}
		
		/**
		 * ```tree
		 * value_str*?
		 * ```
		 */
		value_str(id: any, next?: any) {
			return this.Form().value_str(id, next)
		}
		
		/**
		 * ```tree
		 * Form $mol_form_draft
		 * 	model <= vacancy
		 * 	value_str*? => value_str*?
		 * 	form_fields /
		 * 		<= Scope_field
		 * 		<= Company_field
		 * 		<= Tasks_field
		 * 		<= Requirements_field
		 * 		<= Tests_field
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.model = () => this.vacancy()
			obj.form_fields = () => [
				this.Scope_field(),
				this.Company_field(),
				this.Tasks_field(),
				this.Requirements_field(),
				this.Tests_field()
			] as readonly any[]
			
			return obj
		}
	}
	
}

