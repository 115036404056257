namespace $ {
	export class $hyoo_sync_online extends $mol_link {
		
		/**
		 * ```tree
		 * minimal_width 40
		 * ```
		 */
		minimal_width() {
			return 40
		}
		
		/**
		 * ```tree
		 * minimal_height 40
		 * ```
		 */
		minimal_height() {
			return 40
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_yard
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * uri <= master_link
		 * ```
		 */
		uri() {
			return this.master_link()
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Well
		 * 	<= Fail
		 * ```
		 */
		sub() {
			return [
				this.Well(),
				this.Fail()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * attr *
		 * 	^
		 * 	title <= message
		 * ```
		 */
		attr() {
			return {
				...super.attr(),
				title: this.message()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * master_link \
		 * ```
		 */
		master_link() {
			return ""
		}
		
		/**
		 * ```tree
		 * Well $mol_avatar id <= master_link
		 * ```
		 */
		@ $mol_mem
		Well() {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.master_link()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Fail $mol_icon_sync_off
		 * ```
		 */
		@ $mol_mem
		Fail() {
			const obj = new this.$.$mol_icon_sync_off()
			
			return obj
		}
		
		/**
		 * ```tree
		 * hint \$hyoo_sync
		 * ```
		 */
		hint() {
			return "$hyoo_sync"
		}
		
		/**
		 * ```tree
		 * message <= hint
		 * ```
		 */
		message() {
			return this.hint()
		}
	}
	
}

