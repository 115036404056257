namespace $ {
	export class $hyoo_intern_cabinet_mentor extends $hyoo_intern_cabinet {
		
		/**
		 * ```tree
		 * profile_title \Профиль наставника
		 * ```
		 */
		profile_title() {
			return "Профиль наставника"
		}
		
		/**
		 * ```tree
		 * spreads *
		 * 	profile <= Profile
		 * 	company <= Company_page
		 * 	vacancy <= Vacancy_page
		 * 	interns <= Interns
		 * ```
		 */
		spreads() {
			return {
				profile: this.Profile(),
				company: this.Company_page(),
				vacancy: this.Vacancy_page(),
				interns: this.Interns()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Company $hyoo_intern_company_card
		 * ```
		 */
		@ $mol_mem
		Company() {
			const obj = new this.$.$hyoo_intern_company_card()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_page $mol_page
		 * 	title \Организация
		 * 	body / <= Company
		 * ```
		 */
		@ $mol_mem
		Company_page() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Организация"
			obj.body = () => [
				this.Company()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Vacancy $hyoo_intern_vacancy_card
		 * ```
		 */
		@ $mol_mem
		Vacancy() {
			const obj = new this.$.$hyoo_intern_vacancy_card()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Vacancy_page $mol_page
		 * 	title \Заявка на стажера
		 * 	body / <= Vacancy
		 * ```
		 */
		@ $mol_mem
		Vacancy_page() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Заявка на стажера"
			obj.body = () => [
				this.Vacancy()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Interns $hyoo_intern_catalog_interns menu_title \Отклики на заявку
		 * ```
		 */
		@ $mol_mem
		Interns() {
			const obj = new this.$.$hyoo_intern_catalog_interns()
			
			obj.menu_title = () => "Отклики на заявку"
			
			return obj
		}
	}
	
}

