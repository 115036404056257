namespace $ {
	export class $hyoo_intern_catalog_scroll extends $mol_book2 {
		
		/**
		 * ```tree
		 * Placeholder null
		 * ```
		 */
		Placeholder() {
			return null as any
		}
		
		/**
		 * ```tree
		 * ids / \test
		 * ```
		 */
		ids() {
			return [
				"test"
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * ids_numered /
		 * ```
		 */
		ids_numered() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * pages /
		 * 	<= Rows
		 * 	<= Pages
		 * ```
		 */
		pages() {
			return [
				this.Rows(),
				this.Pages()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Page* $mol_view sub /
		 * 	<= Page_link*
		 * 	<= Page_content*
		 * ```
		 */
		@ $mol_mem_key
		Page(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Page_link(id),
				this.Page_content(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * menu_title \Rows
		 * ```
		 */
		menu_title() {
			return "Rows"
		}
		
		/**
		 * ```tree
		 * menu_tools /$mol_view
		 * ```
		 */
		menu_tools() {
			return [
			] as readonly $mol_view[]
		}
		
		/**
		 * ```tree
		 * row_title* \
		 * ```
		 */
		row_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * id* \
		 * ```
		 */
		id(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * click*? null
		 * ```
		 */
		@ $mol_mem_key
		click(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Row_link*0 $mol_link
		 * 	title <= row_title*
		 * 	arg * id <= id*
		 * 	click*? <=> click*?
		 * ```
		 */
		@ $mol_mem_key
		Row_link(id: any) {
			const obj = new this.$.$mol_link()
			
			obj.title = () => this.row_title(id)
			obj.arg = () => ({
				id: this.id(id)
			} as Record< string, any >)
			obj.click = (id: any, next?: any) => this.click(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * links / <= Row_link*0
		 * ```
		 */
		links() {
			return [
				this.Row_link("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Row_list $mol_list rows <= links
		 * ```
		 */
		@ $mol_mem
		Row_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.links()
			
			return obj
		}
		
		/**
		 * ```tree
		 * menu_body / <= Row_list
		 * ```
		 */
		menu_body() {
			return [
				this.Row_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_foot /$mol_view
		 * ```
		 */
		menu_foot() {
			return [
			] as readonly $mol_view[]
		}
		
		/**
		 * ```tree
		 * Rows $mol_page
		 * 	title <= menu_title
		 * 	tools <= menu_tools
		 * 	body <= menu_body
		 * 	foot <= menu_foot
		 * ```
		 */
		@ $mol_mem
		Rows() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.menu_title()
			obj.tools = () => this.menu_tools()
			obj.body = () => this.menu_body()
			obj.foot = () => this.menu_foot()
			
			return obj
		}
		
		/**
		 * ```tree
		 * spreads /
		 * ```
		 */
		spreads() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Pages $mol_scroll sub <= spreads
		 * ```
		 */
		@ $mol_mem
		Pages() {
			const obj = new this.$.$mol_scroll()
			
			obj.sub = () => this.spreads()
			
			return obj
		}
		
		/**
		 * ```tree
		 * page_title* \
		 * ```
		 */
		page_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Page_link* $mol_link
		 * 	minimal_height 40
		 * 	title <= page_title*
		 * 	arg * id <= id*
		 * ```
		 */
		@ $mol_mem_key
		Page_link(id: any) {
			const obj = new this.$.$mol_link()
			
			obj.minimal_height = () => 40
			obj.title = () => this.page_title(id)
			obj.arg = () => ({
				id: this.id(id)
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * page_content* /$mol_view_content
		 * ```
		 */
		page_content(id: any) {
			return [
			] as readonly $mol_view_content[]
		}
		
		/**
		 * ```tree
		 * Page_content* $mol_view sub <= page_content*
		 * ```
		 */
		@ $mol_mem_key
		Page_content(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.page_content(id)
			
			return obj
		}
	}
	
}

