namespace $.$$ {

	export class $hyoo_intern_catalog_curators extends $.$hyoo_intern_catalog_curators {

		entity_links() {
			return super.entity_links() as $hyoo_intern_entity_links< typeof $hyoo_intern_person >
		}

	}

}
