namespace $ {
	export class $hyoo_intern_cabinet_curator extends $hyoo_intern_cabinet_admin {
		
		/**
		 * ```tree
		 * profile_title \Профиль куратора
		 * ```
		 */
		profile_title() {
			return "Профиль куратора"
		}
	}
	
}

