namespace $ {
	export class $hyoo_intern_manager_card extends $mol_view {
		
		/**
		 * ```tree
		 * disabled false
		 * ```
		 */
		disabled() {
			return false
		}
		
		/**
		 * ```tree
		 * manager $hyoo_intern_manager
		 * ```
		 */
		@ $mol_mem
		manager() {
			const obj = new this.$.$hyoo_intern_manager()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub / <= Form
		 * ```
		 */
		sub() {
			return [
				this.Form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * person $hyoo_intern_person
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_intern_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person $hyoo_intern_person_card
		 * 	person <= person
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Person() {
			const obj = new this.$.$hyoo_intern_person_card()
			
			obj.person = () => this.person()
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_field $mol_form_field Content <= Person
		 * ```
		 */
		@ $mol_mem
		Person_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.Content = () => this.Person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * company $hyoo_intern_company
		 * ```
		 */
		@ $mol_mem
		company() {
			const obj = new this.$.$hyoo_intern_company()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company $hyoo_intern_company_card
		 * 	company <= company
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Company() {
			const obj = new this.$.$hyoo_intern_company_card()
			
			obj.company = () => this.company()
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_field $mol_form_field
		 * 	name \Организация
		 * 	Content <= Company
		 * ```
		 */
		@ $mol_mem
		Company_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Организация"
			obj.Content = () => this.Company()
			
			return obj
		}
		
		/**
		 * ```tree
		 * value_str*?
		 * ```
		 */
		value_str(id: any, next?: any) {
			return this.Form().value_str(id, next)
		}
		
		/**
		 * ```tree
		 * Form $mol_form_draft
		 * 	model <= manager
		 * 	value_str*? => value_str*?
		 * 	form_fields /
		 * 		<= Person_field
		 * 		<= Company_field
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.model = () => this.manager()
			obj.form_fields = () => [
				this.Person_field(),
				this.Company_field()
			] as readonly any[]
			
			return obj
		}
	}
	
}

