namespace $.$$ {

	$mol_style_define( $hyoo_intern, {
		
		// Role: {
		// 	flex: {
		// 		direction: 'column',
		// 	},
		// },
		// Campaign_id: {
		// 	flex: {
		// 		grow: 0,
		// 	},
		// },
		
	} )

}
