namespace $ {
	export class $hyoo_intern_catalog_companies extends $hyoo_intern_catalog_entities {
		
		/**
		 * ```tree
		 * menu_title \Организации
		 * ```
		 */
		menu_title() {
			return "Организации"
		}
		
		/**
		 * ```tree
		 * Card_view* $hyoo_intern_company_card
		 * 	disabled true
		 * 	company <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_view(id: any) {
			const obj = new this.$.$hyoo_intern_company_card()
			
			obj.disabled = () => true
			obj.company = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card_edit* $hyoo_intern_company_card company <= item*
		 * ```
		 */
		@ $mol_mem_key
		Card_edit(id: any) {
			const obj = new this.$.$hyoo_intern_company_card()
			
			obj.company = () => this.item(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter_form_fields / <= Scope_field
		 * ```
		 */
		filter_form_fields() {
			return [
				this.Scope_field()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * item* $hyoo_intern_company
		 * ```
		 */
		@ $mol_mem_key
		item(id: any) {
			const obj = new this.$.$hyoo_intern_company()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Scope $mol_string
		 * ```
		 */
		@ $mol_mem
		Scope() {
			const obj = new this.$.$mol_string()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Scope_field $mol_form_field
		 * 	name \Сфера деятельности
		 * 	Content <= Scope
		 * ```
		 */
		@ $mol_mem
		Scope_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Сфера деятельности"
			obj.Content = () => this.Scope()
			
			return obj
		}
	}
	
}

