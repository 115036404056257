namespace $ {
	export class $hyoo_intern_person_card extends $mol_view {
		
		/**
		 * ```tree
		 * disabled false
		 * ```
		 */
		disabled() {
			return false
		}
		
		/**
		 * ```tree
		 * person $hyoo_intern_person
		 * ```
		 */
		@ $mol_mem
		person() {
			const obj = new this.$.$hyoo_intern_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub / <= Form
		 * ```
		 */
		sub() {
			return [
				this.Form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Person_id $mol_string
		 * 	value <= value_str*id
		 * 	disabled true
		 * ```
		 */
		@ $mol_mem
		Person_id() {
			const obj = new this.$.$mol_string()
			
			obj.value = () => this.value_str("id")
			obj.disabled = () => true
			
			return obj
		}
		
		/**
		 * ```tree
		 * Person_id_field $mol_form_field
		 * 	name \person_id
		 * 	Content <= Person_id
		 * ```
		 */
		@ $mol_mem
		Person_id_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "person_id"
			obj.Content = () => this.Person_id()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Peer $mol_string
		 * 	value? <=> value_str*peer_id?
		 * 	disabled true
		 * ```
		 */
		@ $mol_mem
		Peer() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("peer_id", next)
			obj.disabled = () => true
			
			return obj
		}
		
		/**
		 * ```tree
		 * Peer_field $mol_form_field
		 * 	name \peer_id
		 * 	Content <= Peer
		 * ```
		 */
		@ $mol_mem
		Peer_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "peer_id"
			obj.Content = () => this.Peer()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_string
		 * 	value? <=> value_str*name?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("name", next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name \Имя
		 * 	Content <= Name
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Имя"
			obj.Content = () => this.Name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_family $mol_string
		 * 	value? <=> value_str*name_family?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Name_family() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("name_family", next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_family_field $mol_form_field
		 * 	name \Фамилия
		 * 	Content <= Name_family
		 * ```
		 */
		@ $mol_mem
		Name_family_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Фамилия"
			obj.Content = () => this.Name_family()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email $mol_string
		 * 	value? <=> value_str*email?
		 * 	disabled <= disabled
		 * ```
		 */
		@ $mol_mem
		Email() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.value_str("email", next)
			obj.disabled = () => this.disabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email_field $mol_form_field
		 * 	name \Почта
		 * 	Content <= Email
		 * ```
		 */
		@ $mol_mem
		Email_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Почта"
			obj.Content = () => this.Email()
			
			return obj
		}
		
		/**
		 * ```tree
		 * value_str*?
		 * ```
		 */
		value_str(id: any, next?: any) {
			return this.Form().value_str(id, next)
		}
		
		/**
		 * ```tree
		 * Form $mol_form_draft
		 * 	model <= person
		 * 	value_str*? => value_str*?
		 * 	form_fields /
		 * 		<= Person_id_field
		 * 		<= Peer_field
		 * 		<= Name_field
		 * 		<= Name_family_field
		 * 		<= Email_field
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.model = () => this.person()
			obj.form_fields = () => [
				this.Person_id_field(),
				this.Peer_field(),
				this.Name_field(),
				this.Name_family_field(),
				this.Email_field()
			] as readonly any[]
			
			return obj
		}
	}
	
}

