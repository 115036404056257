namespace $ {
	export class $hyoo_intern_cabinet_manager extends $hyoo_intern_cabinet {
		
		/**
		 * ```tree
		 * profile_title \Профиль специалиста ОК
		 * ```
		 */
		profile_title() {
			return "Профиль специалиста ОК"
		}
		
		/**
		 * ```tree
		 * spreads *
		 * 	profile <= Profile
		 * 	company <= Company_page
		 * 	mentors <= Mentors
		 * 	vacancies <= Vacancies
		 * ```
		 */
		spreads() {
			return {
				profile: this.Profile(),
				company: this.Company_page(),
				mentors: this.Mentors(),
				vacancies: this.Vacancies()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Company $hyoo_intern_company_card
		 * ```
		 */
		@ $mol_mem
		Company() {
			const obj = new this.$.$hyoo_intern_company_card()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Company_page $mol_page
		 * 	title \Организация
		 * 	body / <= Company
		 * ```
		 */
		@ $mol_mem
		Company_page() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Организация"
			obj.body = () => [
				this.Company()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Mentors $hyoo_intern_catalog_mentors
		 * ```
		 */
		@ $mol_mem
		Mentors() {
			const obj = new this.$.$hyoo_intern_catalog_mentors()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Vacancies $hyoo_intern_catalog_vacancies
		 * ```
		 */
		@ $mol_mem
		Vacancies() {
			const obj = new this.$.$hyoo_intern_catalog_vacancies()
			
			return obj
		}
	}
	
}

