namespace $ {
	export class $hyoo_intern_catalog_entities extends $hyoo_intern_catalog_scroll {
		
		/**
		 * ```tree
		 * entity_links $hyoo_intern_entity_links
		 * ```
		 */
		@ $mol_mem
		entity_links() {
			const obj = new this.$.$hyoo_intern_entity_links()
			
			return obj
		}
		
		/**
		 * ```tree
		 * access \mod
		 * ```
		 */
		access() {
			return "mod"
		}
		
		/**
		 * ```tree
		 * item* null
		 * ```
		 */
		item(id: any) {
			return null as any
		}
		
		/**
		 * ```tree
		 * Page_content_view* $mol_list sub / <= Card_view*
		 * ```
		 */
		@ $mol_mem_key
		Page_content_view(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.sub = () => [
				this.Card_view(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Page_content_edit* $mol_list sub /
		 * 	<= Card_edit*
		 * 	<= Drop*
		 * ```
		 */
		@ $mol_mem_key
		Page_content_edit(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.sub = () => [
				this.Card_edit(id),
				this.Drop(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * menu_tools / <= Filter
		 * ```
		 */
		menu_tools() {
			return [
				this.Filter()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_foot / <= Add
		 * ```
		 */
		menu_foot() {
			return [
				this.Add()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Filter_form $mol_form_draft
		 * 	form_fields <= filter_form_fields
		 * 	buttons / <= Apply_filter
		 * ```
		 */
		@ $mol_mem
		Filter_form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.form_fields = () => this.filter_form_fields()
			obj.buttons = () => [
				this.Apply_filter()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card_view* $mol_view
		 * ```
		 */
		@ $mol_mem_key
		Card_view(id: any) {
			const obj = new this.$.$mol_view()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Card_edit* $mol_view
		 * ```
		 */
		@ $mol_mem_key
		Card_edit(id: any) {
			const obj = new this.$.$mol_view()
			
			return obj
		}
		
		/**
		 * ```tree
		 * drop* null
		 * ```
		 */
		drop(id: any) {
			return null as any
		}
		
		/**
		 * ```tree
		 * Drop* $mol_button_minor
		 * 	title \Удалить
		 * 	click <= drop*
		 * ```
		 */
		@ $mol_mem_key
		Drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Удалить"
			obj.click = () => this.drop(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Filter_icon $mol_icon_filter
		 * ```
		 */
		@ $mol_mem
		Filter_icon() {
			const obj = new this.$.$mol_icon_filter()
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter_opened? false
		 * ```
		 */
		@ $mol_mem
		filter_opened(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Filter $mol_check_icon
		 * 	Icon <= Filter_icon
		 * 	checked? <=> filter_opened?
		 * ```
		 */
		@ $mol_mem
		Filter() {
			const obj = new this.$.$mol_check_icon()
			
			obj.Icon = () => this.Filter_icon()
			obj.checked = (next?: any) => this.filter_opened(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * add? null
		 * ```
		 */
		@ $mol_mem
		add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Add $mol_button_minor
		 * 	sub / <= Add_icon
		 * 	click? <=> add?
		 * ```
		 */
		@ $mol_mem
		Add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.sub = () => [
				this.Add_icon()
			] as readonly any[]
			obj.click = (next?: any) => this.add(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter_form_fields /
		 * ```
		 */
		filter_form_fields() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Apply_filter $mol_button_minor title \Применить
		 * ```
		 */
		@ $mol_mem
		Apply_filter() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Применить"
			
			return obj
		}
	}
	
}

