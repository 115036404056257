namespace $.$$ {

	export class $hyoo_intern_cabinet_admin extends $.$hyoo_intern_cabinet_admin {

		add_curator() {
			const person_id = this.add_curator_person_id()
			if (!$mol_int62_string_ensure( person_id )){
				$mol_fail( new $mol_data_error('person_id isnt int64_string') )
			}
			const curator = this.campaign().curator_add( $mol_int62_string_ensure( person_id )! )
			return curator
		}

		add_mentor() {
			const person_id = this.add_mentor_person_id()
			if (!$mol_int62_string_ensure( person_id )){
				$mol_fail( new $mol_data_error('person_id isnt int64_string') )
			}
			const mentor = this.campaign().curator_add( $mol_int62_string_ensure( person_id )! )
			return mentor
		}

	}

}
