namespace $ {
	export class $hyoo_intern extends $mol_book2 {
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * user $hyoo_intern_person
		 * ```
		 */
		@ $mol_mem
		user() {
			const obj = new this.$.$hyoo_intern_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * campaign $hyoo_intern_campaign
		 * ```
		 */
		@ $mol_mem
		campaign() {
			const obj = new this.$.$hyoo_intern_campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_client
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_client()
			
			return obj
		}
		
		/**
		 * ```tree
		 * role? \norole
		 * ```
		 */
		@ $mol_mem
		role(next?: any) {
			if ( next !== undefined ) return next as never
			return "norole"
		}
		
		/**
		 * ```tree
		 * cabinets *
		 * 	norole <= Sign_cabinet
		 * 	admin <= Admin_cabinet
		 * 	curator <= Curator_cabinet
		 * 	intern <= Intern_cabinet
		 * 	mentor <= Mentor_cabinet
		 * 	manager <= Manager_cabinet
		 * ```
		 */
		cabinets() {
			return {
				norole: this.Sign_cabinet(),
				admin: this.Admin_cabinet(),
				curator: this.Curator_cabinet(),
				intern: this.Intern_cabinet(),
				mentor: this.Mentor_cabinet(),
				manager: this.Manager_cabinet()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sign_cabinet $hyoo_intern_cabinet_norole
		 * 	yard <= yard
		 * 	user <= user
		 * 	campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Sign_cabinet() {
			const obj = new this.$.$hyoo_intern_cabinet_norole()
			
			obj.yard = () => this.yard()
			obj.user = () => this.user()
			obj.campaign = () => this.campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Admin_cabinet $hyoo_intern_cabinet_admin
		 * 	yard <= yard
		 * 	user <= user
		 * 	campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Admin_cabinet() {
			const obj = new this.$.$hyoo_intern_cabinet_admin()
			
			obj.yard = () => this.yard()
			obj.user = () => this.user()
			obj.campaign = () => this.campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Curator_cabinet $hyoo_intern_cabinet_curator
		 * 	yard <= yard
		 * 	user <= user
		 * 	campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Curator_cabinet() {
			const obj = new this.$.$hyoo_intern_cabinet_curator()
			
			obj.yard = () => this.yard()
			obj.user = () => this.user()
			obj.campaign = () => this.campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Intern_cabinet $hyoo_intern_cabinet_intern
		 * 	yard <= yard
		 * 	user <= user
		 * 	campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Intern_cabinet() {
			const obj = new this.$.$hyoo_intern_cabinet_intern()
			
			obj.yard = () => this.yard()
			obj.user = () => this.user()
			obj.campaign = () => this.campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Mentor_cabinet $hyoo_intern_cabinet_mentor
		 * 	yard <= yard
		 * 	user <= user
		 * 	campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Mentor_cabinet() {
			const obj = new this.$.$hyoo_intern_cabinet_mentor()
			
			obj.yard = () => this.yard()
			obj.user = () => this.user()
			obj.campaign = () => this.campaign()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Manager_cabinet $hyoo_intern_cabinet_manager
		 * 	yard <= yard
		 * 	user <= user
		 * 	campaign <= campaign
		 * ```
		 */
		@ $mol_mem
		Manager_cabinet() {
			const obj = new this.$.$hyoo_intern_cabinet_manager()
			
			obj.yard = () => this.yard()
			obj.user = () => this.user()
			obj.campaign = () => this.campaign()
			
			return obj
		}
	}
	
}

